<template>
  <div class="cover">
    <el-row type="flex" justify="space-around" style="margin-top: 4px">
      <el-col :sm="6">
        <el-card v-loading="loading" class="box-card">
          <div slot="header" style="text-align: center">
            <img alt="ALIADDO" width="200" src="@/assets/img/svg/logo-aliaddo.svg" height="45">
          </div>
          <el-button v-if="confirm" :loading="loading" style="width: 100%" type="primary" @click.prevent="onGoToService">
            Iniciar sesión
          </el-button>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { i18n } from '../../i18n'

import Router from '../../router'

export default {
  components: {},
  data() {
    return {
      loading: false,
      confirm: false,
    }
  },
  mounted() {
    const _this = this
    _this.confirmEmail()
  },
  methods: {
    confirmEmail() {
      const _this = this
      _this.loading = true
      _this.$http
        .get(
          `users/${_this.$route.params.id}/confirmEmail/?code=${_this.$route.params.code}`,
        )
        .then(
          response => {
            _this.user = response.body
            _this.loading = false
            _this.confirm = true
            _this.$swal(
              i18n.t('ok'),
              'El e-mail ha sido confirmado exitosamente',
              'success',
            )
          },
          response => {
            _this.loading = false
          },
        )
    },
    onGoToService() {
      window.location.replace(
        'https://app.aliaddo.com',
        '_blank',
      )
    },
    login() {
      Router.push({ name: 'Login' })
    },
  },
}
</script>
